import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.isDialogVisible,"scrollable":"","max-width":"400px"},on:{"input":function (val) { return _vm.$emit('update:is-dialog-visible', val); }}},[_c(VCard,[_c(VCardTitle,[_vm._v("以下账单收款人未确认，请先确认！")]),_c(VDivider),_c(VCardText,{staticStyle:{"height":"300px","white-space":"pre-line"}},_vm._l((_vm.unConfirmArr),function(item){return _c('span',{key:item.id},[_vm._v(_vm._s(item.channelName)+"("+_vm._s(item.month)+"月)"),_c('br')])}),0),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.$emit('update:is-dialog-visible', false)}}},[_vm._v(" 好的 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }